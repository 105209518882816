<template>
    <div v-if="hasPreview" class="link-preview">
        <a :href="url" target="_blank">
            <img class="link-preview-image" :src="linkPreviewUrl" />
        </a>
    </div>
</template>

<script>
import http from 'services/axios';
import { DATA_API_URL } from 'config';
export default {
    props: {
        url: {
            type: String,
            required: true,
        },
    },
    data: () => ({
        linkPreviewUrl: null,
    }),
    computed: {
        hasPreview() {
            return this.linkPreviewUrl;
        },
    },
    watch: {
        url: {
            immediate: true,
            handler: 'fetchPreview',
        },
    },
    methods: {
        async fetchPreview() {
            let response = await http.get(DATA_API_URL + '/api/v1/link-preview?url=' + this.url);

            this.linkPreviewUrl = response.data.image;
        },
    },
};
</script>

<style scoped>
.link-preview-image {
    height: auto;
    margin-bottom: 20px;
    width: 100%;
}
</style>
